import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import TextArea from '@/components/TextArea/TextArea.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import HxPdfExporter from '../../../components/HxPdfExporter/HxPdfExporter.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import ScheduleService from '@/services/API/schedule.service';
import DeliveryService from '@/services/API/delivery.service.js';
import TransferService from '@/services/API/transfer.service';
import AddCostDetailService from '../../../services/API/add_cost_details.service';
import EstimateParticularService from '../../../services/API/estimate_particular.service';
import DocumentService from '@/services/API/document.service';
import socketClient from '../../../services/SOCKET';
import ProjectService from '@/services/API/project.service';
import HearingService from '@/services/API/hearings.service';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import CommonConst from '@/constants/CommonConst';
import emitSoundMixin from '@/mixin/emitSound';
import DeliveryConst from '../../../constants/DeliveryConst.js';
import redDotNotifyMixin from '../../../mixin/redDotNotifyMixin.js';
import DecisionService from '@/services/API/decision.service';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'DeliveryScreen',
	mixins: [emitSoundMixin, redDotNotifyMixin],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		TextArea,
		SaveButtonArea,
		BottomButton,
		NotificationModal,
		HxPdfExporter,
	},
	data() {
		return {
			screenIcon: 'delivery-icon',
			screenName: 'コンセプト仕上げ',
			uploadedContents: [],
			isDelivery: true,
			showSaveButton: true,
			showButton: false,
			imageComment: '',
			expiration: '60',
			imageUrl: '',
			middleContent: ' 様 ／ ',
			deliveryNotification: '納品データをお待ちください',
			buttonType: 'single-btn',
			showBottomButton: false,
			bottomButtonName: '受け取り完了',
			selectiveModalId: 'delivery-selective-id',
			selectiveContents: DeliveryConst.SELECTIVE_CONTENT,
			deliveryConfirmedTextShow: false,
			deliverySentTextShow: false,
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			pdfData: {},
			addCostDetails: [],
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,
			deliveryStatus: '',
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'clientMode',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		noDataTextShow() {
			if (this.uploadedContents && this.uploadedContents.length > 0) {
				return false;
			}

			return true;
		},
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		// mailTemplate() {
		// 	return {
		// 		destinations: this.isSendCreator
		// 			? [this.creatorInfo['email_address']]
		// 			: [this.clientInfo['email_address']],
		// 		sender: this.managementMasterInfo['sender_email_address'],
		// 		subject: this.subject,
		// 		template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
		// 		template_params: {
		// 			projectName: this.projectInfo['project_name'],
		// 			projectId: this.projectId,
		// 			screenName: this.screenName,
		// 			clientName: this.clientInfo['client_name'],
		// 			clientId: this.clientInfo['id'],
		// 			emailContent: this.mailContent,
		// 			sendMailDate: moment().format('LLL'),
		// 			appLogo: this.appLogo,
		// 			appLogoFooter: this.appLogoPlain,
		// 			processingScreenUrl: this.isSendCreator
		// 				? `${this.projectInfo['process_creator_url']}delivery`
		// 				: `${this.projectInfo['process_client_url']}delivery`,
		// 			clientRegisterParams:
		// 				`register_name=${this.clientInfo['client_name']}&` +
		// 				`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
		// 				`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
		// 		},
		// 	};
		// },
		// lineMessage() {
		// 	return toLineMessageTemplate({
		// 		title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
		// 		message: this.mailContent,
		// 		currentUrl: this.isSendCreator
		// 			? `${this.projectInfo['process_creator_url']}delivery`
		// 			: `${this.projectInfo['process_client_url']}delivery`,
		// 	});
		// },

		// /* eslint-disable no-mixed-spaces-and-tabs */
		// notifyContent() {
		// 	return this.projectInfo['notify_method'] == 0
		// 		? this.mailTemplate
		// 		: this.projectInfo['notify_method'] == 1
		// 		? this.lineMessage
		// 		: {
		// 				lineContent: this.lineMessage,
		// 				mailContent: this.mailTemplate,
		// 		  }; // eslint-disable-line no-mixed-spaces-and-tabs
		// },
	},

	watch: {
		schedule(newVal) {
			this.pdfData.estimateParticular['delivery_date'] = newVal['delivery_date'];
		},

		// Calculate amount when consumption_tax_rate and addcostDetails existed
		managementMasterInfo(newVal) {
			if (newVal['consumption_tax_rate']) {
				this.computeAddCostDetailsMoneyAmount(
					newVal['consumption_tax_rate'],
					this.addCostDetails
				);
			}
		},

		deliveryStatus(newStatus) {
			if (newStatus === DeliveryConst.SAVED_NUMBER) {
				this.setRedDotNotiy(CommonConst.SCREEN_ID.DELIVERY);
			} else {
				this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.DELIVERY);
			}
		},
	},
	filters: {
		/**
		 * Filter file name
		 * @param {String} value image file key
		 * @returns file name
		 */
		toFileName: function (value) {
			if (!value) return '';
			return value.split('/').at(-1);
		},
	},

	methods: {
		/**
		 * Calculate subtotal of all addCostDetail records
		 * @param {Array} addCostDetails AddCostDetail array
		 * @returns addCostDetailsSubtotal
		 */
		addCostDetailsSubtotal(addCostDetails) {
			if (addCostDetails && addCostDetails.length > 0) {
				return addCostDetails.reduce((acc, addCostDetail) => {
					return (
						acc + Math.trunc(addCostDetail['quantity'] * addCostDetail['unit_price'])
					);
				}, 0);
			}
		},

		/**
		 * Calculate Addcost Details money amount
		 * @param {Integer} consumptionTaxRate ManagementMaster consumption tax rate
		 * @param {Array } addCostDetails AddCostDetail array
		 */
		computeAddCostDetailsMoneyAmount(consumptionTaxRate, addCostDetails) {
			if (consumptionTaxRate && addCostDetails.length > 0) {
				let addCostDetailsSubtotal = this.addCostDetailsSubtotal(addCostDetails);

				let addCostDetailsConsumptionTax =
					(addCostDetailsSubtotal * consumptionTaxRate) / 100;
				let subtotal =
					this.originEstimateData.estimateParticular['subtotal'] +
					addCostDetailsSubtotal;
				let consumptionTax = Math.trunc(
					this.originEstimateData.estimateParticular['consumption_tax'] +
						addCostDetailsConsumptionTax
				);
				this.originEstimateData.estimateParticular['subtotal'] = subtotal;
				let holdingTax = 0;
				if (this.projectInfo.with_holding_tax == 1) {
					if (subtotal < 1000000) {
						holdingTax = (subtotal * 10.21) / 100;
					} else {
						holdingTax = ((subtotal - 1000000) * 20.42) / 100 + 102100;
					}
				}
				holdingTax = Math.trunc(holdingTax);
				this.originEstimateData.estimateParticular['holding_tax'] = holdingTax;
				this.originEstimateData.estimateParticular['subtotal'] = subtotal;
				this.originEstimateData.estimateParticular['consumption_tax'] = consumptionTax;
				this.originEstimateData.estimateParticular['total'] =
					subtotal + consumptionTax + holdingTax;
			}
		},

		async onDownload(event, key) {
			// Prevent download when click to children
			if (event.target === event.currentTarget) {
				let fileName = key.split('/').at(-1);
				let imageUrl = await this._getPreviewImgUrl(key, this.expiration);
				let imageBase64 = await this._toDataURL(imageUrl);
				let aTag = document.createElement('a');
				aTag.href = imageBase64;
				aTag.download = fileName;
				aTag.click();

				await this._getDeliveryData(this.projectId);
			}
		},

		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},

		onConfirmDelivery() {
			console.log('Confirm Delivery');

			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		async onSendConfirmRequire() {
			this.emitMixinFinishingSound(true),
				// Update client_finished of project and direct to finish screen
				await this._updateProject(this.projectId, {
					client_finished: 1,
				});
			let projectInfo = {
				...this.projectInfo,
				client_finished: 1,
				status: DeliveryConst.DELIVERY_SCREEN_ID + 1,
			};
			console.log({ projectInfo });
			await this.$store.commit('setProjectInfo', projectInfo);

			let updateContents = [];
			this.uploadedContents.forEach((item) => {
				let updateContent = {
					id: item['id'],
					is_save: DeliveryConst.CONFIRMED_NUMBER,
				};
				updateContents.push(updateContent);
			});
			await this._updateBulkDeliveries(updateContents);

			this.deliveryConfirmedTextShow = true;
			this.deliverySentTextShow = false;
			this.showBottomButton = false;
			this.deliveryStatus = DeliveryConst.CONFIRMED_NUMBER;

			// Render DeliverySplit Template
			this.$refs.hxPdfExporterDeliverySplit._renderTemplateString();

			this.$emit('on-send-confirm-info', {
				screenId: DeliveryConst.DELIVERY_SCREEN_ID,
				confirmInfo: true,
			});

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'delivery_confirm',
				},
				room: this.projectId,
			});
			// Set status finish
			let status = {
				status: CommonConst.SCREEN_STATUS.FINISH,
				confirm_flag: 1,
			};
			await this._updateScheduleData(status);
			await this._updateHearingsStatus(this.projectId, status);
			await this._updateDecision(this.projectId, {
				status: CommonConst.SCREEN_STATUS.FINISH,
				confirm_flag: 1,
			});
			// // send mail case 55
			// let hour =
			// 	this.projectInfo['zoom_session_duration'] < 60
			// 		? 0
			// 		: Math.floor(this.projectInfo['zoom_session_duration'] / 60);
			// let minute = Math.round(this.projectInfo['zoom_session_duration']) % 60;
			// let totalZoomMettingTime = hour + '時間' + minute + '分';

			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］納品完了`;
			// this.mailContent = `納品が完了しました。\nお疲れ様でした。\n今回のCoMoDe(共創)タイム：${totalZoomMettingTime}\n\n請求書を作成できます。`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 納品完了`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 納品完了`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 納品完了`;
			// this.mailContent = `納品が完了しました。\nお疲れ様でした。\n今回のCoMoDe(共創)タイム：${totalZoomMettingTime}`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);

			this.$router.push(CommonConst.ROUTE_NAME.FINISH);
		},
		async _updateScheduleData(scheduleInfo) {
			try {
				let response = await ScheduleService.update(this.projectId, scheduleInfo);
				if (response && response.status === 200) {
					console.log('Update schedule info succesfully');
				}
				return true;
			} catch (error) {
				console.log(`Update schedule info failed: ${error}`);
				return false;
			}
		},
		async _updateDecision(projectId, decisionUpdateData) {
			try {
				let response = await DecisionService.update(projectId, {
					doc_content: decisionUpdateData,
				});
				if (!response || response.status !== 200) {
					throw 'Update Decision failed';
				}
				console.log('%c Update Decision successfully!', 'color: green');
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		async _updateHearingsStatus(projectId, status) {
			try {
				let response = await HearingService.update(projectId, status);
				if (!response || response.status !== 200) {
					console.log('%c Update Hearing failed', 'color: red');
					return false;
				}
				console.log('%c Update Hearing successfully', 'color: green');
				return true;
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},
		/**
		 * Render Purchase Order
		 */
		async onRenderDeliverySplit() {
			console.log('On render DeliverySplit');
			let pdf = await this.$refs.hxPdfExporterDeliverySplit.getRenderedPDFTemplate();
			console.log('On render DeliverySplit');

			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: 'delivery-split.pdf',
			};
			console.log('On render DeliverySplit');

			let deliverySplitFileKey = await this._uploadPdf(pdfFileParams);
			await this._updateDocument(this.projectId, {
				delivery_split: deliverySplitFileKey,
			});
		},

		/**
		 * Get DeliveryData
		 * @param {Integer} projectId Project ID
		 * @returns
		 */
		async _getDeliveryData(projectId) {
			try {
				let response = await DeliveryService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Delivery failed!';
				}

				let uploadedContents = this._filterSavedItem([...response.data]);
				if (uploadedContents.length > 0) {
					this.uploadedContents = uploadedContents.reverse();
					this.deliveryNotification = 'クリエイターから納品ファイルが届きました。';
					this.showBottomButton = true;
					this.deliveryStatus = this.uploadedContents[0]['is_save'];
				}

				if (
					this.uploadedContents &&
					this.uploadedContents.length > 0 &&
					this.uploadedContents[0]['is_save'] === 1
				) {
					this.deliverySentTextShow = true;
					this.showBottomButton = true;
				}

				if (
					this.uploadedContents &&
					this.uploadedContents.length > 0 &&
					this.uploadedContents[0]['is_save'] === 2
				) {
					this.deliveryConfirmedTextShow = true;
					this.showBottomButton = false;
				}

				if (
					this.uploadedContents &&
					this.uploadedContents.length > 0 &&
					this.uploadedContents[0]['is_save'] === 3
				) {
					this.deliveryConfirmedTextShow = true;
					this.showBottomButton = false;
				}

				console.log('%c Get Delivery successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Update bulk contents
		 * @param {Array} contents update contents
		 */
		async _updateBulkDeliveries(contents) {
			let response = await DeliveryService.updateBulk(contents);
			if (response && response.status === 200) {
				console.log('%c Update Bulk Delivery successfully!', 'color: green');
			} else {
				throw response.message;
			}
		},

		/**
		 * Filter saved delivery items
		 * @param {Array} deliveries deliveries Array from database
		 * @returns Saved delivery items
		 */
		_filterSavedItem(deliveries) {
			let savedDelivery = deliveries.filter((item) => {
				if (
					[
						DeliveryConst.SAVED_NUMBER,
						DeliveryConst.CONFIRMED_NUMBER,
						DeliveryConst.FINISH_NUMBER,
					].includes(item['is_save'])
				)
					return true;
			});
			return savedDelivery;
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularAndChildren(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);

				if (response && response.status === 200) {
					let data = response.data;
					let estimateParticularId = data['estimate_particular']['id'];
					let addCostDetails = await this._getAddCostDetails(estimateParticularId);
					this.addCostDetails = addCostDetails;

					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});

					let additionData = {
						project_name: this.projectInfo['project_name'],
						delivery_date: this.schedule.delivery_date,
					};

					this.originEstimateData.estimateParticular = {
						...data['estimate_particular'],
						...additionData,
					};

					this.computeAddCostDetailsMoneyAmount(
						this.managementMasterInfo['consumption_tax_rate'],
						this.addCostDetails
					);

					this.originEstimateData.estimateDetails =
						estimateDetails.concat(addCostDetails);
					this.pdfData = { ...this.originEstimateData };
				} else {
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				return addCostDetails;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		async _updateDocument(projectId, content) {
			try {
				let response = await DocumentService.update(projectId, content);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				console.log('%c Update document successfully', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: green');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		async _updateProject(projectId, content) {
			try {
				let response = await ProjectService.update(projectId, content);
				if (!response || response.status !== 200) {
					throw 'Upload Project failed!';
				}
				console.log('%c Upload Project successfully!', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},
	},
	async mounted() {
		moment.locale('ja');
		await this._getDeliveryData(this.projectId);
		this._getEstimateParticularAndChildren(this.projectId);
		this.$store.commit('setIsAppProcessing', true);
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'delivery_content':
					await this._getDeliveryData(this.projectId);
					break;
				case 'delivery_confirm':
					if (!!this.preview || this.clientMode) {
						this.emitMixinFinishingSound(true);

						let projectInfo = {
							...this.projectInfo,
							client_finished: 1,
							status: DeliveryConst.DELIVERY_SCREEN_ID,
						};
						await this.$store.commit('setProjectInfo', projectInfo);

						this.deliveryConfirmedTextShow = true;
						this.deliverySentTextShow = false;
						this.showBottomButton = false;
						this.deliveryStatus = DeliveryConst.CONFIRMED_NUMBER;

						this.$emit('on-send-confirm-info', {
							screenId: DeliveryConst.DELIVERY_SCREEN_ID,
							confirmInfo: true,
						});
						this.$router.push(CommonConst.ROUTE_NAME.FINISH);
					}
					break;
				case 'client-remove-reddot':
					if (!!this.preview || this.clientMode)
						this.removeRedDotNotify(CommonConst.SCREEN_ID.DELIVERY);
					break;

				default:
					break;
			}
		});
		moment.locale('ja');
		await this._getDeliveryData(this.projectId);
		await this._getEstimateParticularAndChildren(this.projectId);
		this.$store.commit('setIsAppProcessing', false);
	},
};
