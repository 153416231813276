const SELECTIVE_CONTENT = '納品ファイルの受け取りを完了します';
const SAVED_NUMBER = 1;
const CONFIRMED_NUMBER = 2;
const FINISH_NUMBER = 3;
const DELIVERY_SCREEN_ID = 3;

export default {
	SELECTIVE_CONTENT,
	SAVED_NUMBER,
	CONFIRMED_NUMBER,
	FINISH_NUMBER,
	DELIVERY_SCREEN_ID,
};
